<template>
  <div
    :class="[,
      { 'dropdown-button' : type === 'standard',
        'dropdown-button__pill' : type === 'pill',
        'dropdown-button--active': isActive && !disabled,
        'dropdown-button--disabled': disabled,
      }]"
    @keyup.enter.prevent="onEnter"
  >
    <slot>
      <dropdown-label
        v-model:active="isActive"
        :disabled="disabled"
      >
        <slot />
      </dropdown-label>
    </slot>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

import DropdownLabel from '@/components/shared/dropdown-label.vue';

export default {
  components: {
    DropdownLabel,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'standard',
    },
  },

  emits: ['update:active'],

  setup(props, { emit }) {
    const isActive = ref(props.active);

    const onEnter = () => {
      isActive.value = !isActive.value;
    };

    watch(() => isActive.value, (value) => {
      emit('update:active', value);
    });

    watch(() => props.active, (value) => {
      isActive.value = value;
    });

    return {
      isActive,
      onEnter,
    };
  },
};
</script>

<style scoped>
.dropdown-button {
  border: 0.0625rem solid var(--colour-border-light);
  border-radius: 0.25rem;
}

.dropdown-button:hover {
  background-color: var(--colour-bg-alternative);
}

.dropdown-button :deep(.dropdown-label) {
  font-size: 0.875rem;
  line-height: 0.375rem;
}

.dropdown-button__pill :deep(.pill-dropdown-label) {
  font-size: 0.75rem;
  line-height: 0.375rem;
}

.dropdown-button:focus {
  outline: 0.0625rem solid var(--colour-border-default);
  outline-offset: -0.0625rem;
}

.dropdown-button--active {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-button__pill--active {
  margin-bottom: 1rem;
  border-bottom: 0;
}

.dropdown-button--active:focus {
  outline: 0;
  outline-offset: 0;
}

.dropdown-button--active:focus :deep(.dropdown-label) {
  outline: 0.0625rem solid var(--colour-border-default);
  outline-offset: -0.0625rem;
}

.dropdown-button--disabled:hover {
  background-color: var(--colour-bg-default);
}

.dropdown-button--disabled:focus {
  outline: 0.0625rem solid var(--colour-border-lightest);
  outline-offset: -0.0625rem;
}

.dropdown-button--disabled:focus :deep(.dropdown-label) {
  outline: none;
  box-shadow: none;
}
</style>
