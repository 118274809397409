<template>
  <div
    ref="dropdown"
    class="dropdown"
  >
    <slot name="activator" />
    <transition name="slide-down">
      <div
        v-if="visible && !disabled"
        :style="styles"
        :class="['dropdown__container',
                 {'dropdown__container__pill' : type === 'pill'},
        ]"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    alignment: {
      type: String,
      default: undefined, // undefined, left, right
    },

    maxHeight: {
      type: Number,
      default: 20,
    },

    disabled: {
      type: Boolean,
      required: false,
    },

    type: {
      type: String,
      default: 'standard',
    },
  },

  setup(props) {
    const styles = computed(() => ({
      [props.alignment]: 0,
      maxHeight: `${props.maxHeight}rem`,
    }));

    return {
      styles,
    };
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  background-color: var(--colour-bg-default);
}

.dropdown__disabled {
  color: var(--colour-disabled-ui);
  cursor: default;
}

.dropdown__container {
  position: absolute;
  top: 100%;
  z-index: var(--z-dropdown);
  width: 100%;
  min-width: 100%;
  overflow: auto;
  cursor: pointer;
  background: var(--colour-bg-default);
  border: 0.0625rem solid var(--colour-border-light);
  border-top: 0;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.dropdown__container__pill {
  margin-top: 1rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
}
</style>
