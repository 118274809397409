<template>
  <div
    class="modal-wrapper"
    :class="{
      'modal-wrapper--open' : enabled,
      'modal-wrapper--close' : !enabled,
    }"
    tabindex="-1"
    @keydown="onKeyDown"
  >
    <div
      class="modal-wrapper__backdrop modal-wrapper__backdrop--dark"
      @mousedown="onMouseDown"
    />
    <div
      ref="focusRef"
      data-focus-guard="true"
      tabindex="0"
      style="width:1px;height:0px;padding:0;overflow:hidden;position:fixed;top:1px;left:1px"
    />
    <div data-focus-lock-disabled="false">
      <template v-if="type === 'prompt'">
        <div
          aria-label="Accessibility header for a modal"
          class="prompt"
          :class="{
            'prompt--enter': enabled,
            'prompt--close': !enabled,
          }"
          aria-modal="true"
          role="dialog"
          tabindex="-1"
        >
          <div class="prompt__content-wrapper">
            <div
              tabindex="0"
              class="prompt__content"
            >
              <h2
                class="prompt__heading"
              >
                {{ title }}
              </h2>
              <slot>
                <div
                  v-if="info"
                >
                  {{ info }}
                </div>
                <div
                  v-for="data in dataArray"
                  :key="toHash(data)"
                >
                  <div
                    class="preference__modal_info"
                  >
                    <span class="preference__modal_description">
                      {{ data.key }}
                    </span>
                    <span
                      class="preference__modal_code"
                    >
                      {{ data.value }}
                    </span>
                    <div
                      class="preference__modal_copy_icon"
                      @click="writeToClipboard(data.value)"
                    />
                  </div>
                </div>
              </slot>
            </div>
            <div class="modal-footer modal-footer--compact modal-footer--borderless modal__footer">
              <button
                v-if="primaryButtonText"
                type="button"
                :disabled="primaryButtonDisabled"
                class="btn btn--small"
                :class="getButtonTypeClass(styles?.type) || 'btn--primary'"
                @click="onPrimaryButtonClicked"
              >
                <span class="btn__inner modal__btn_inner">
                  <span class="btn__label">
                    {{ primaryButtonText }}
                  </span>
                </span>
              </button>
              <button
                v-if="secondaryButtonText"
                type="button"
                :disabled="secondaryButtonDisabled"
                class="btn btn--small"
                :class="getButtonTypeClass(styles?.secondaryType) || 'btn--secondary'"
                @click="onSecondaryButtonClicked"
              >
                <span class="btn__inner modal__btn_inner">
                  <span class="btn__label">
                    {{ secondaryButtonText }}
                  </span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="type === 'sheets'">
        <div
          aria-label="Accessibility header for a modal"
          class="sheets sheets--small"
          :class="{
            'sheets--enter': enabled,
            'sheets--close': !enabled,
          }"
          aria-modal="true"
          role="dialog"
          tabindex="-1"
        >
          <div class="modal-header">
            <button
              type="button"
              class="btn btn--small btn--icon-primary-inverse modal-header__close"
              aria-label="Close modal"
              @click="onCloseClicked"
            >
              <span class="btn__inner">
                <svg
                  focusable="false"
                  viewBox="0 0 24 24"
                  class="svg-icon btn__icon"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="m11.9997 13.4149 4.9498 4.9497 1.4142-1.4142-4.9498-4.9497
                      4.9498-4.9498-1.4142-1.4142-4.9498 4.9498L7.05 5.6367 5.6357
                      7.051l4.9498 4.9498-4.9497 4.9497L7.05 18.3646l4.9497-4.9497z"
                  />
                </svg>
              </span>
            </button>
            <!--div class="modal-header__title-wrapper modal-header__title-wrapper--padded">
              <span class="modal-header__title">{{ title }}</span>
            </div-->
          </div>
          <div
            class="sheets__content-wrapper"
            tabindex="0"
          >
            <div class="modal-body">
              <h2 class="sheets__heading">
                {{ title }}
              </h2>
              <div
                v-if="info"
                class="sheets__info text text--body-s"
              >
                {{ info }}
              </div>
              <slot />
            </div>
          </div>
          <div class="modal-footer modal-footer--compact modal__footer">
            <button
              v-if="primaryButtonText"
              type="button"
              :disabled="primaryButtonDisabled"
              class="btn btn--small"
              :class="getButtonTypeClass(styles?.type) || 'btn--primary'"
              @click="onPrimaryButtonClicked"
            >
              <span class="btn__inner modal__btn_inner">
                <span class="btn__label">
                  {{ primaryButtonText }}
                </span>
              </span>
            </button>
            <button
              v-if="secondaryButtonText"
              type="button"
              :disabled="secondaryButtonDisabled"
              class="btn btn--small"
              :class="getButtonTypeClass(styles?.secondaryType) || 'btn--secondary'"
              @click="onSecondaryButtonClicked"
            >
              <span class="btn__inner modal__btn_inner">
                <span class="btn__label">
                  {{ secondaryButtonText }}
                </span>
              </span>
            </button>
          </div>
        </div>
      </template>
    </div>
    <div
      data-focus-guard="true"
      tabindex="0"
      style="width:1px;height:0px;padding:0;overflow:hidden;position:fixed;top:1px;left:1px"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useUtilsStore } from '@/store/utils';

export default {
  props: {
    enabled: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      default: 'prompt',
    },
    title: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
    dataArray: {
      type: Array,
      default: () => [],
    },
    primaryButtonText: {
      type: String,
      default: '',
    },
    secondaryButtonText: {
      type: String,
      default: '',
    },
    primaryButtonDisabled: {
      type: Boolean,
      default: false,
    },
    secondaryButtonDisabled: {
      type: Boolean,
      default: false,
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['secondaryClicked', 'primaryClicked', 'closeModal'],

  setup(props, { emit }) {
    const { toHash } = useUtilsStore();
    const focusRef = ref(null);
    const escapable = computed(() => props.type === 'sheets');

    const onSecondaryButtonClicked = () => {
      emit('secondaryClicked');
    };

    const onPrimaryButtonClicked = () => {
      emit('primaryClicked');
    };

    const onCloseClicked = () => {
      emit('closeModal');
    };

    const onKeyDown = (e) => {
      // close modal by using the esc key
      const escapeCalledOnThisModal = !e.target.closest || e.target.closest('.modal-wrapper') === e.currentTarget;
      if (escapeCalledOnThisModal && escapable.value && e.key === 'Escape') {
        emit('closeModal');
      }
    };

    const onMouseDown = (e) => {
      // close modal by tapping the backdrop
      if (escapable.value && e.target === e.currentTarget) {
        emit('closeModal');
      }
    };

    const buttonClasses = {
      type: {
        primary: 'btn--primary',
        secondary: 'btn--secondary',
        danger: 'btn--danger',
      },
      size: {
        small: 'btn--small',
        medium: 'btn--medium',
      },
    };

    const getButtonTypeClass = (t) => (t ? buttonClasses.type[t] : '');

    const writeToClipboard = (text) => {
      navigator.clipboard.writeText(text);
    };

    watch(() => props.enabled, (isEnabled) => {
      // move focus to modal when enabled
      if (isEnabled) {
        focusRef.value.focus();
      }
    });

    return {
      onSecondaryButtonClicked,
      onPrimaryButtonClicked,
      onCloseClicked,
      onKeyDown,
      onMouseDown,
      writeToClipboard,
      toHash,
      buttonClasses,
      getButtonTypeClass,
      focusRef,
    };
  },
};
</script>

<style scoped>
.prompt {
  min-width: 31.5rem;
}

.preference__modal_description {
  padding-right: 0.25rem;
  font-weight: bold;
}

.preference__modal_copy_icon {
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.5rem;
  content: '';
  background: transparent;
  background-image: url("../../assets/svg/copy.svg");
  background-repeat: no-repeat;
  background-size: 1.125rem;
}

.preference__modal_copy_icon:hover {
  cursor: pointer;
}

.preference__modal_info {
  display: flex;
}

.prompt .modal__btn_inner {
  width: 10.25rem;
}

.prompt .modal__footer {
  width: fit-content;
}

.sheets__heading {
  margin-top: -1.5rem;
  margin-bottom: 1rem;
}

.sheets__info {
  margin-top: -1rem;
  margin-bottom: 2rem;
}
</style>
