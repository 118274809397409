<template>
  <a
    aria-haspopup="true"
    class="dropdown-label"
    :aria-expanded="active"
    @keydown.enter.prevent="toggle()"
    @click="toggle()"
  >
    <slot />
  </a>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:active'],

  setup(props, { emit }) {
    const isActive = ref(props.active);

    const toggle = () => {
      isActive.value = !isActive.value;
      emit('update:active', isActive.value);
    };

    watch(() => props.active, (value) => {
      isActive.value = value;
    });

    return {
      isActive,
      toggle,
    };
  },
};
</script>

<style scoped>
@import "../../assets/styles/vars.css";

.dropdown-label {
  cursor: pointer;
  background: transparent;
}
</style>
