<template>
  <button
    type="button"
    class="btn btn--small"
    aria-label="Close modal"
    @click="toggleNavigation"
  >
    <span class="btn__inner">
      <slot />
    </span>
  </button>
</template>

<style scoped>
  .btn__inner {
    padding: 0;
  }
</style>
