<template>
  <div>
    <tabs
      :tabs="tabs"
      :selected-tab="activeTab"
      :has-line="false"
      inverted
      use-bottom-bar
      @tabClicked="changeTab"
    />
    <modal
      :title="'Please Confirm'"
      :enabled="isChangeModalEnabled"
      :primary-button-text="'Confirm'"
      :secondary-button-text="'Cancel'"
      :info="'You have made changes that have not been saved.'"
      @secondaryClicked="onChangeModalCancelClicked"
      @primaryClicked="onChangeModalConfirmClicked"
    />
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';

import { ref } from 'vue';

import Tabs from '@/components/shared/tabs.vue';
import Modal from '@/components/shared/modal.vue';

import { usePreferences } from '@/composables/system-management/preferences';

import { usePreferenceManagementStore } from '@/store/preference-management';
import { useUtilsStore } from '@/store/utils';

import { storeToRefs } from 'pinia';
import { cloneDeep } from 'lodash';

export default {
  components: {
    Tabs,
    Modal,
  },

  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const route = useRoute();

    const router = useRouter();

    const {
      preference,
    } = usePreferences();

    const {
      preferenceValueChanges,
    } = storeToRefs(usePreferenceManagementStore());

    const {
      preferenceRoute,
    } = storeToRefs(useUtilsStore());

    const {
      storePreferenceRoute,
    } = useUtilsStore();

    const activeTab = ref(props.tabs.find((tab) => tab.name.toLowerCase() === route.name));
    const clickedTab = ref();
    const isChangeModalEnabled = ref(false);

    const onChangeModalCancelClicked = () => {
      isChangeModalEnabled.value = false;
      clickedTab.value = undefined;
    };

    const onChangeModalConfirmClicked = () => {
      router.push({
        name: clickedTab.value.name.toLowerCase(),
        query: {
          productCode: route.query.productCode,
        },
      });
      clickedTab.value = undefined;
    };

    const changeTab = (tab) => {
      clickedTab.value = tab;
      if (activeTab.value?.name === 'Preferences') {
        storePreferenceRoute(cloneDeep(route));
      }
      if (preferenceValueChanges.value) {
        isChangeModalEnabled.value = true;
      } else if (tab.name === 'Preferences') {
        activeTab.value = tab;
        if (preferenceRoute.value && (route.query.productCode === preferenceRoute.value?.query.productCode)) {
          router.push(preferenceRoute.value);
        } else {
          router.push({
            name: tab.name.toLowerCase(),
            query: {
              productCode: route.query.productCode,
            },
          });
        }
      } else {
        router.push({
          name: tab.name.toLowerCase(),
          query: {
            productCode: route.query.productCode,
          },
        });
      }
    };

    return {
      route,
      preference,
      changeTab,
      activeTab,
      isChangeModalEnabled,
      onChangeModalCancelClicked,
      onChangeModalConfirmClicked,
    };
  },
};
</script>
