<template>
  <a
    aria-haspopup="true"
    :class="['dropdown-label',
             {
               'dropdown-label__standard': type === 'standard',
               'pill pill--small dropdown-label__pill': type === 'pill',
               'dropdown-label--active': isActive,
               'dropdown-label__pill--active': isActive && type === 'pill',
               'dropdown-label--disabled': disabled,
             }]"
    :aria-expanded="active"
    @keydown.enter.prevent="toggle()"
    @click="toggle()"
  >
    <slot />
  </a>
</template>

<script>
import { ref, watch } from 'vue';

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'standard',
    },
  },

  emits: ['update:active'],

  setup(props, { emit }) {
    const isActive = ref(props.active);

    const toggle = () => {
      isActive.value = !isActive.value;
      emit('update:active', isActive.value);
    };

    watch(() => props.active, (value) => {
      isActive.value = value;
    });

    return {
      isActive,
      toggle,
    };
  },
};
</script>

<style scoped>
@import "../../assets/styles/vars.css";

.dropdown-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-x: hidden;
  font-size: 0.75rem;
  line-height: 1.375rem;
  white-space: nowrap;
  cursor: pointer;
}

.dropdown-label__standard {
  padding: 0.875rem 0.625rem 0.75rem 0.875rem;
  color: var(--colour-text-default);
  background: transparent;
}

.dropdown-label__pill {
  color: var(--colour-text-dark);
}

.dropdown-label__pill--active {
  border: solid var(--colour-border-dark) 0.0625rem;
}

.dropdown-label:hover {
  text-decoration: none;
}

.dropdown-label::after {
  height: 0.5rem;
  padding-right: 0.75rem;
  margin-left: 1rem;
  content: '';
  background: transparent;
  background-image: url("../../assets/svg/chevron-down.svg");
  background-repeat: no-repeat;
  background-size: 0.75rem;
}

/* Active state */
.dropdown-label--active {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.dropdown-label--active::after {
  background-image: url("../../assets/svg/chevron-up.svg");
}

/* Disabled state */
.dropdown-label--disabled {
  color: var(--colour-disabled-ui);
  cursor: default;
}

.dropdown-label--disabled::after {
  background-image: url("../../assets/svg/chevron-down.svg");
  opacity: 0.1;
}
</style>
