<template>
  <div
    ref="dropref"
    class="image-dropdown"
  >
    <dropdown
      v-model:visible="visible"
      v-arrow-navigable
      class="image-dropdown__dropdown"
      @keyup.tab="hide"
    >
      <template #activator>
        <dropdown-button
          v-model:active="visible"
          tabindex="0"
          data-arrow-navigable-trigger
          data-arrow-navigable-item
          class="image-dropdown__button"
        >
          <dropdown-label
            v-model:active="visible"
          >
            <img
              :src="imageSrc"
              alt="Dropdown image"
              class="image-dropdown__image"
            >
          </dropdown-label>
        </dropdown-button>
      </template>

      <list
        class="image-dropdown__list"
      >
        <list-item
          v-for="item in items"
          :key="toHash(item)"
          data-arrow-navigable-item
          tabindex="0"
          @click="selectItem(item)"
          @keyup.enter.prevent="selectItem(item)"
        >
          <span>
            {{ item.displayName }}
          </span>
        </list-item>
      </list>
    </dropdown>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useOnOutsidePress } from 'vue-composable';

import Dropdown from '@/components/shared/dropdown.vue';
import DropdownButton from '@/components/shared/dropdown-button.vue';
import DropdownLabel from '@/components/shared/image-dropdown-label.vue';
import List from '@/components/shared/list.vue';
import ListItem from '@/components/shared/list-item.vue';

import { useVisibility } from '@/composables/visibility';
import { useUtilsStore } from '@/store/utils';

export default {
  components: {
    Dropdown,
    DropdownButton,
    DropdownLabel,
    List,
    ListItem,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
  },

  emits: ['itemSelected'],

  setup(props, { emit }) {
    const { visible, show, hide } = useVisibility();
    const { toHash } = useUtilsStore();

    const dropref = ref(null);
    useOnOutsidePress(dropref, () => hide());

    const selectItem = (item) => {
      emit('itemSelected', item);
      hide();
    };

    return {
      dropref,
      selectItem,
      visible,
      toHash,
      show,
      hide,
    };
  },
};
</script>

<style scoped>
.image-dropdown__image {
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;
  border-radius: 100%;
}

.image-dropdown__dropdown {
  background-color: transparent;
  border-radius: 0.25rem;
}

.image-dropdown__button {
  border: none;
}

.image-dropdown__button:hover {
  background-color: transparent;
}

.image-dropdown__button:focus {
  outline: none;
}

.image-dropdown__button:focus .dropdown-label {
  outline: none;
}
</style>

<style>
.image-dropdown__dropdown > .dropdown__container {
  right: 0;
  width: max-content;
  padding: 0.5rem 0;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}
</style>
