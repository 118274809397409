<template>
  <li
    class="list-item"
    @click="selectItem(item)"
    @keydown.enter.prevent="selectItem(item)"
  >
    <slot>{{ item[value] }}</slot>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: 'name',
    },
  },
  emits: ['itemSelected'],

  setup(props, { emit }) {
    const selectItem = (item) => emit('itemSelected', item);

    return {
      selectItem,
    };
  },
};
</script>

<style scoped>
.list-item {
  padding: 0.5rem 0.875rem;
  margin: 0;
  border-top: 0.0625rem solid var(--colour-bg-alternative);
}

.list-item:hover {
  background-color: var(--colour-bg-alternative);
}

.list-item:focus {
  outline: 0.025rem solid var(--colour-border-default);
  outline-offset: -0.0625rem;
}
</style>
