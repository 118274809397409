<template>
  <div class="hamburger">
    <iam-button
      tabindex="0"
      @click="toggleHamburger"
    >
      <div class="hamburger-trigger" />
    </iam-button>
    <div
      :class="['modal-wrapper', {
        'modal-wrapper--close hamburger__modal-wrapper--close': !navOpen,
        'modal-wrapper--open': navOpen,
      }]"
      tabindex="-1"
    >
      <div
        class="modal-wrapper__backdrop hamburger__modal-wrapper__backdrop"
        @click="toggleHamburger"
      />
      <iam-sheet
        v-if="navOpen"
        @close="toggleHamburger"
      >
        <template #sheet-header>
          <iam-button
            tabindex="0"
            @click="toggleHamburger"
          >
            <div class="hamburger-trigger hamburger-trigger--inverse" />
          </iam-button>
          <span
            class="hamburger__home-button"
          >
            <p
              class="hamburger__home-button-text"
              @click="onHomeClicked"
            >
              Home
            </p>
            <SSRIcon
              :paths="home"
              class="hamburger__home-button-icon"
            />
          </span>
        </template>
        <template #sheet-content>
          <nav
            class="hamburger-menu"
          >
            <div class="hamburger__link-title">
              Apps
            </div>
            <ul
              class="hamburger__links"
            >
              <li
                v-for="item in items"
                :key="item.code"
                class="hamburger__link"
                @click="selectProduct(item)"
              >
                <div
                  class="hamburger__link-icon"
                >
                  {{ item.acronym ? item.acronym : (item.code.length &lt; 5 ? item.code : '?') }}
                </div>
                <p
                  class="hamburger__link-name"
                >
                  {{ item.name }}
                </p>
              </li>
            </ul>
          </nav>
        </template>
      </iam-sheet>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import { useRoute, useRouter } from 'vue-router';

import SSRIcon from '@ingka/ssr-icon-vue';
import Icons from '@ingka/ssr-icon-vue/icons';

import iamSheet from '@/components/shared/hamburger-sheet.vue';
import iamButton from '@/components/shared/hamburger-button.vue';

export default {
  components: {
    iamSheet,
    iamButton,
    SSRIcon,
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  emits: ['productSelected'],

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const navOpen = ref(false);
    const toggleHamburger = () => {
      navOpen.value = !navOpen.value;
    };

    const selectProduct = (prod) => {
      emit('productSelected', prod);
      toggleHamburger();
    };

    const onHomeClicked = () => {
      router.push({
        name: 'index',
        query: {
          productCode: route.query.productCode,
        },
      });
      toggleHamburger();
    };

    return {
      navOpen,
      onHomeClicked,
      toggleHamburger,
      selectProduct,
      home: Icons.home,
      arrowRight: Icons.arrowRight,
    };
  },
};
</script>

<style scoped>
.hamburger__modal-wrapper--close {
  -webkit-animation: slide-out 0.5s forwards;
  animation: slide-out 0.5s forwards;
}

.hamburger {
  display: flex;
  align-items: center;
}

.hamburger-trigger {
  width: 2.75rem;
  height: 2.75rem;
  cursor: pointer;
  background-image: url("../../assets/svg/apps-menu.svg");
  background-repeat: no-repeat;
  transition: transform 0.15s ease-in-out;
}

.hamburger-trigger--inverse {
  width: 2rem;
  height: 2rem;
  background-image: url("../../assets/svg/apps-menu-black.svg");
}

.hamburger-menu {
  padding-left: 0.75rem;
}

.hamburger__home-button {
  display: flex;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: var(--colour-bg-accent);
}

.hamburger__home-button-icon {
  width: 1.125rem;
  height: 1.125rem;
  padding-bottom: 0.125rem;
}

.hamburger__home-button-text {
  padding-right: 0.125rem;
}

.hamburger__home-button:hover .hamburger__home-button-text {
  text-decoration: underline;
}

.hamburger__home-button:hover {
  cursor: pointer;
}

.hamburger-trigger:hover {
  transform: rotate(90deg);
}

.hamburger__links {
  height: calc(100vh - 10rem);
  margin-top: 1rem;
  overflow-y: auto;
  list-style: none;
}

.hamburger__link {
  display: flex;
  align-items: center;
  min-height: 3rem;
  font-size: 0.75rem;
  color: var(--colour-text-dark);
}

.hamburger__link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  height: 2rem;
  margin-right: 0.75rem;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: #ffcd3f;
  text-align: center;
  letter-spacing: -0.05em;
  background-color: #151e44;
  background-image: url("../../assets/svg/nav-item-bg-overlay.svg");
  border-radius: 100%;
}

.hamburger__link-name {
  font-size: 0.75rem;
}

.hamburger__link-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.hamburger__link-title {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: var(--colour-text-dark);
}

.hamburger__modal-wrapper__backdrop {
  background-color: var(--colour-bg-default);
  opacity: 0.6;
}

@media (min-width: 40rem) {
  .hamburger-menu {
    padding-left: 1rem;
  }

  .hamburger-trigger--inverse {
    width: 2.75rem;
    height: 2.75rem;
  }

  .hamburger__link {
    min-height: 3.5rem;
  }

  .hamburger__link-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.75rem;
  }

  .hamburger__link-name {
    font-size: 0.875rem;
  }

  .hamburger__link-icon {
    min-width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
