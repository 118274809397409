<template>
  <div
    class="tabs"
  >
    <div
      role="tablist"
      :class="[{'tabs__line': hasLine}, 'tabs__list', 'tabs__top', {'tabs__top--hide': useBottomBar}]"
    >
      <div
        class="overflow-carousel"
      >
        <div
          class="overflow-carousel__content"
        >
          <button
            v-for="tab in tabs"
            :key="tab.name"
            class="tabs__tab"
            role="tab"
            :aria-selected="(selectedTab?.name === tab.name)"
            :aria-controls="tab.name"
            :disabled="tab.disabled"
            :title="tab.disabled ? tab.tooltip : ''"
            tabindex="-1"
            :class="[inverted && 'tabs__inverted',
                     inverted && tab.disabled && 'tabs__inverted--disabled',
                     tab.name === selectedTab?.name && !tab.disabled && 'tabs__tab--active']"
            @click="onTabClicked(tab)"
          >
            {{ tab.name }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="useBottomBar"
      role="tablist"
      class="tabs tabs__bottom"
    >
      <div
        class="overflow-carousel"
      >
        <div
          class="overflow-carousel__content tabs__bottom-carousel"
        >
          <button
            v-for="tab in tabs"
            :key="tab.name"
            class="tabs__tab tabs__tab-bottom"
            role="tab"
            :aria-selected="(selectedTab?.name === tab.name)"
            :aria-controls="tab.name"
            :disabled="tab.disabled"
            :title="tab.disabled ? tab.tooltip : ''"
            tabindex="-1"
            :class="[tab.name === selectedTab?.name && !tab.disabled && 'tabs__tab-bottom--active']"
            @click="onTabClicked(tab)"
          >
            {{ tab.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useUtilsStore } from '@/store/utils';

export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    selectedTab: {
      type: Object,
      required: false,
      default: undefined,
    },
    hasLine: {
      type: Boolean,
      default: true,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
    useBottomBar: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['tabClicked'],
  setup(props, { emit }) {
    const { toHash } = useUtilsStore();
    const onTabClicked = (tab) => {
      emit('tabClicked', tab);
    };

    return {
      onTabClicked,
      toHash,
    };
  },
};
</script>

<style scoped>
.overflow-carousel {
  display: flex;
  overflow-x: auto;
}

.tabs__top--hide {
  display: none;
}

.tabs__line {
  z-index: 1;
}

.tabs__inverted {
  color: var(--colour-neutral-white);
  opacity: 0.8;
}

.tabs__inverted:hover {
  color: var(--colour-neutral-white);
  opacity: 0.6;
}

.tabs__inverted[disabled]:hover {
  color: rgb(51, 51, 51);
  opacity: 0.8;
}

.tabs__inverted::after {
  border-color: var(--colour-neutral-white);
}

.tabs__inverted--disabled {
  color: rgb(51, 51, 51);
}

.tabs__tab--active {
  opacity: 1;
}

.tabs__tab--active:hover {
  cursor: pointer;
  opacity: 1;
}

.tabs__bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  height: 4rem;
  background-color: var(--colour-bg-default);
  border-top: 1px solid var(--colour-border-light);
}

.tabs__tab-bottom {
  padding: 1.35rem 0;
  font-size: 0.75rem;
}

.tabs__tab-bottom--active {
  color: var(--colour-dark);
  border-top: var(--colour-semantic-informative) solid 0.1875rem;
}

.tabs__bottom > .overflow-carousel {
  display: flex;
  justify-content: center;
}

@media (min-width: 56.25rem) {
  .tabs__bottom {
    display: none;
  }

  .tabs__top {
    display: flex;
  }
}
</style>
