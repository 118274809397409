/* eslint-disable import/prefer-default-export */
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import {
  getPreferenceValue,
  getPreferenceValues,
  getPreferenceValueList,
  getPreferenceTypes,
  putPreferenceValue,
  postPreferenceValue,
  deletePreferenceValue,
} from '@/services/system-management';

const defaultState = {
  preference: undefined,
  preferences: [],
  preferenceValue: undefined,
  futurePreferenceValue: undefined,
  preferenceValues: [],
  preferenceValueList: [],
  preferenceValueListInfo: { next: 0, hasMore: false, total: 0 },
  preferenceValueChanges: 0,
  preferenceTypes: [],
};

export const usePreferenceManagementStore = defineStore('preferenceManagement', () => {
  const initialState = () => cloneDeep(defaultState);

  const preferenceValue = ref(defaultState.preferenceValue);
  const preferenceValueChanges = ref(defaultState.preferenceValueChanges);
  const futurePreferenceValue = ref(defaultState.futurePreferenceValue);
  const preferenceValues = ref(defaultState.preferenceValues);
  const preferenceValueList = ref(defaultState.preferenceValueList);
  const preferenceValueListInfo = ref(defaultState.preferenceValueListInfo);
  const preferenceTypes = ref(defaultState.preferenceTypes);

  /**
  * @deprecated Use the state param directly instead of the store function.
  */
  const storePreferenceValue = (prefValue) => {
    preferenceValue.value = prefValue;
  };
  const storePreferenceValueChanges = (preferenceChanges) => {
    preferenceValueChanges.value = preferenceChanges;
  };
  const resetPreferenceTypes = () => {
    preferenceTypes.value = initialState().preferenceTypes;
  };

  const fetchPreferenceTypes = async ({ productCode }) => {
    try {
      const payload = await getPreferenceTypes({ productCode });
      preferenceTypes.value = payload.preferenceTypes;
      return true;
    } catch (err) {
      console.log(
        'Error in fetchPreferenceTypes:',
        err.response?.status,
        err.response?.data.error?.message,
      );
      return false;
    }
  };

  const fetchFuturePreferenceValue = async (queries) => {
    console.log('fetchFuturePreferenceValue queries', queries);
    const payload = await getPreferenceValue(queries);
    futurePreferenceValue.value = payload;

    if (!preferenceValue.value.allValues[`${futurePreferenceValue.value.preferenceValues[0].startTime}`]) {
      preferenceValue.value
        .allValues[`${futurePreferenceValue.value.preferenceValues[0].startTime}`] = futurePreferenceValue.value
          .preferenceValues[0].values;
      console.log('fetchFuturePreferenceValue preferenceValue.value.allValues', preferenceValue.value.allValues);
      return true;
    }
    return false;
  };

  const updatePreferenceValue = async (queries) => {
    console.log('updatePreferenceValue, queries', queries);
    try {
      await putPreferenceValue(queries);
      return true;
    } catch (err) {
      console.log('Error in update/put:', err.response.status, err.response.data.error?.message);
      return false;
    }
  };

  const createPreferenceValue = async (queries) => {
    console.log('createPreferenceValue, queries', queries);
    try {
      await postPreferenceValue(queries);
      return true;
    } catch (err) {
      console.log('Error in create/post:', err.response.status, err.response.data.error?.message);
      return false;
    }
  };

  const clearPreferenceValue = async (queries) => {
    console.log('deletePreferenceValue');
    try {
      await deletePreferenceValue(queries);
      return true;
    } catch (err) {
      console.log('Error in clear/delete:', err.response.status, err.response.data.error?.message);
      return false;
    }
  };

  /** TODO update comment
   * Queries the existing list of preference values stored in the state module object
   * by preferenceCode and product code.
   * @param {Object} preferenceQuery - The query object.
   * @param {string} preferenceQuery.preferenceCode - The preference code
   * to match a preference value object.
   * @param {string} preferenceQuery.productCode - The product code
   * to match a preference value object.
   * @returns The first occurrance in the preference value list that matches the query.
   */
  const queryPreferenceValue = (preferenceQuery) => {
    const thePreferenceValue = preferenceValueList.value
      .filter((thePreference) => preferenceQuery.preferenceCode === thePreference.preferenceCode
      && preferenceQuery.productCode === thePreference.productCode).pop();

    if (!thePreferenceValue) {
      return undefined;
    }

    if (!thePreferenceValue.allValues) {
      thePreferenceValue.allValues = {};
    }

    console.log(
      'thePreferenceValue', thePreferenceValue,
      'thePreferenceValue.allValues', thePreferenceValue.allValues,
      'thePreferenceValue.startTime', thePreferenceValue.startTime,
    );
    thePreferenceValue.allValues[thePreferenceValue.startTime] = thePreferenceValue?.values
      || thePreferenceValue?.inherited?.values;

    return thePreferenceValue;
  };

  let latestfetchPreferenceValueListQueries = {};
  const fetchPreferenceValueList = async (queries) => {
    latestfetchPreferenceValueListQueries = queries;
    try {
      const payload = await getPreferenceValueList(queries);

      if (
        latestfetchPreferenceValueListQueries.productCode === queries.productCode
        && latestfetchPreferenceValueListQueries.offset === queries.offset
        && latestfetchPreferenceValueListQueries.limit === queries.limit
        && latestfetchPreferenceValueListQueries.hierarchyCode === queries.hierarchyCode
        && latestfetchPreferenceValueListQueries.hierarchyNodeCode === queries.hierarchyNodeCode
        && latestfetchPreferenceValueListQueries.hierarchyNodeType === queries.hierarchyNodeType
        && latestfetchPreferenceValueListQueries.preferenceName !== queries.preferenceName
      ) return true;
      preferenceValueList.value = payload.preferenceValues;
      preferenceValueListInfo.value = { next: Number(payload.next), hasMore: payload.hasMore, total: Number(payload.total) };
      return true;
    } catch (err) {
      console.log('Error in fetchPreferenceValueList:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  const fetchMoreForPreferenceValueList = async (queries) => {
    try {
      const payload = await getPreferenceValueList(queries);
      preferenceValueListInfo.value = { next: Number(payload.next), hasMore: payload.hasMore, total: Number(payload.total) };
      preferenceValueList.value.push(...payload.preferenceValues);
      return true;
    } catch (err) {
      console.log('Error in fetchMoreForPreferenceValueList:', err.response?.status, err.response?.data.error?.message);
      return false;
    }
  };

  /**
   * Supporting in the choice of which preference type component to use.
   * @param {Object} preferenceType - The preference type object.
   * @param {string} preferenceType.type - The type which is either
   * Single, Collection or Dictionary.
   * @param {string} preferenceType.baseType - The base type which is either
   * String, Number, Boolean, Hierarchy Node or Date.
   * @returns A string pointing out the component that matches the preference type.
   */
  const getPreferenceValueTypeComponent = (type) => {
    let typeString = 'preference-type-single-string';
    if (type === 'Single') {
      typeString = 'preference-type-single';
    } else if (type === 'Collection') {
      typeString = 'preference-type-collection';
    } else if (type === 'Dictionary') {
      typeString = 'preference-type-dictionary';
    }

    return typeString;
  };

  /**
   * Return the values object as it is
   * @param {Object} preference - The preference object retrieved from backend.
   * @returns The value object.
   */
  const getValidPreferenceValues = (preference) => preference?.values || (preference?.inherited
      && preference.inherited?.values);

  /**
   * Return the first value in the values object. Normally used for "single" preference types.
   * @param {Object} preference - The preference object retrieved from backend.
   * @returns The value object.
   */
  const getValidPreferenceValue = (preference) => getValidPreferenceValues(preference)[0];

  return {
    preferenceTypes,
    getPreferenceTypes,
    fetchPreferenceTypes,
    resetPreferenceTypes,
    preferenceValue,
    storePreferenceValue,
    clearPreferenceValue,
    getValidPreferenceValue,
    queryPreferenceValue,
    updatePreferenceValue,
    createPreferenceValue,
    getPreferenceValueTypeComponent,
    fetchFuturePreferenceValue,
    preferenceValues,
    getValidPreferenceValues,
    getPreferenceValues,
    preferenceValueList,
    preferenceValueListInfo,
    fetchPreferenceValueList,
    fetchMoreForPreferenceValueList,
    preferenceValueChanges,
    storePreferenceValueChanges,
  };
});
