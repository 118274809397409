import { ref } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export const useVisibility = (initialValue = false) => {
  const visible = ref(initialValue);
  const toggle = () => {
    visible.value = !visible.value;
    return visible;
  };
  const show = () => { visible.value = true; };
  const hide = () => { visible.value = false; };

  return {
    visible,
    toggle,
    show,
    hide,
  };
};
