<template>
  <div
    data-focus-lock-disabled="disabled"
  >
    <div
      aria-label="Accessibility header for a modal"
      class="
        sheets
        sheets--small
        sheets--mirrored
        sheets--preserve-alignment
        sheets--enter
      "
      aria-modal="true"
      role="dialog"
      tabindex="-1"
    >
      <div class="sheet-header">
        <slot name="sheet-header" />
      </div>
      <div
        class="sheet__content-wrapper"
        tabindex="0"
      >
        <slot name="sheet-content" />
      </div>
      <div class="sheet-footer">
        <slot name="sheet-footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close'],
};
</script>

<style scoped>
.sheets {
  --sheets-background-color: var(--colour-bg-default);

  max-width: 16rem;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  background: var(--sheets-background-color);
  border-radius: 0;
  box-shadow: 0 8px 10px -5px #0003, 0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f;
}

.sheet-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 2.75rem;
}

.sheet-footer {
  padding: 0.875rem 0 0.875rem 0.75rem;
  border-top: 0;
}

.sheets--preserve-alignment.sheets--mirrored {
  border-radius: 0;
}

@media (min-width: 40rem) {
  .sheets {
    padding: 0.625rem 1rem 0.625rem 0.625rem;
    padding-left: 0.625rem;
  }
}
</style>
