<template>
  <div class="page-header">
    <hamburger
      :items="sortedProducts"
      @productSelected="onProductSelected"
    />
    <div class="page-header__heading">
      <div
        class="page-header__title"
        @click="onAtkomstClick"
      >
        <span>Åtkomst</span>
      </div>
    </div>
    <navigation
      :tabs="mainTabs"
    />
    <div class="page-header__user">
      <image-dropdown
        :items="actorProfileDropdown"
        :image-src="String(actorPicture)"
        @itemSelected="onDropdownItemSelected($event)"
      />
    </div>
  </div>
</template>

<script>
import {
  onBeforeMount, watch, ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuth0 } from '@auth0/auth0-vue';
import ImageDropdown from '@/components/shared/image-dropdown.vue';

import Navigation from '@/components/domain/navigation.vue';
import Hamburger from '@/components/shared/hamburger.vue';
import { usePreferences } from '@/composables/system-management/preferences';

import { useUserEvaluationStore } from '@/store/user-evaluation';
import { useUserManagementStore } from '@/store/user-management';
import { useVisibility } from '@/composables/visibility';
import { useUtilsStore } from '@/store/utils';

import { usePreferenceManagementStore } from '@/store/preference-management';
import { useActorStore } from '@/store/actor';

import { storeToRefs } from 'pinia';

export default {
  components: {
    Navigation,
    Hamburger,
    ImageDropdown,
  },

  props: {
    hideProductDropdown: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['productSelected', 'productsLoaded'],

  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const auth0 = useAuth0();

    const {
      queryProduct,
      fetchProductsForActor,
      getHasPreferenceValue,
      getPreferenceValue,
    } = useUserEvaluationStore();

    const {
      products,
      sortedProducts,
      hasProductViewPrivilege,
    } = storeToRefs(useUserEvaluationStore());

    const {
      storeProduct,
      resetProduct,
    } = useActorStore();

    const {
      product,
      mainTabs,
    } = storeToRefs(useActorStore());

    const {
      fetchPrivileges,
      resetPrivileges,
      fetchRoles,
      resetRoles,
    } = useUserManagementStore();

    const {
      fetchPreferences,
      resetPreferences,
    } = usePreferences();

    const {
      fetchPreferenceTypes,
      resetPreferenceTypes,
    } = usePreferenceManagementStore();

    const { hide, show, visible } = useVisibility();
    const { toHash } = useUtilsStore();

    const dropref = ref(null);
    const actorProfileDropdown = [{ displayName: 'Sign out', id: 'signOut' }];

    const onDropdownItemSelected = async (item) => {
      if (item.id === 'signOut') {
        const { protocol, host } = window.document.location;
        auth0.logout({
          returnTo: `${protocol}//${host}`,
        });
      }
    };

    const onAtkomstClick = () => {
      router.push({
        name: 'root',
        query: {
          productCode: route.query.productCode,
        },
      });
    };

    const onProductSelected = (prod) => {
      if (prod.url) {
        window.location.href = prod.url;
      } else {
        router.push({
          query: {
            productCode: prod.code,
            ...route.query?.user && { user: route.query.user },
          },
        });
      }
      emit('productSelected', prod);
    };

    const setProduct = async (productCode) => {
      const prod = await queryProduct(productCode);
      if (prod?.code === product?.value?.code) {
        // no need to re-fetch product data
      } else {
        resetRoles();
        resetPrivileges();
        resetPreferences();
        resetPreferenceTypes();
        if (prod?.code && prod.code !== 'All products' && hasProductViewPrivilege.hasPrivilege) {
          // fetching general product data, commonly used in different components
          await Promise.all([
            fetchRoles({ productCode: prod.code }),
            fetchPrivileges({ productCode: prod.code }),
            fetchPreferences({ productCode: prod.code }),
            fetchPreferenceTypes({ productCode: prod.code }),
          ]);
        }
      }
      if (prod) {
        storeProduct(prod);
      } else {
        resetProduct();
      }
    };

    const actorPicture = ref();

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    onBeforeMount(async () => {
      // eslint-disable-next-line no-await-in-loop
      while (auth0.isLoading.value) { await delay(500); }
      if (!auth0.isAuthenticated.value) auth0.loginWithRedirect();
      actorPicture.value = auth0.user.value.picture;
      if (!products.value?.length) { // no need to re-fetch if we have all products for the user
        const payload = {
          productCode: 'DFP',
          hierarchyNodeType: 'PRODUCT',
          privilegeCode: 'ViewProduct',
          userId: auth0.user.value['https://accounts.ikea.com/networkid'],
        };
        await fetchProductsForActor(payload);
      }
      emit('productsLoaded');
      if (route.query.productCode) { // we have a product in the url
        // select product based on the url parameter
        setProduct(route.query.productCode);
      } else if (sortedProducts.value.length === 2) { // All products + single product
        // Select the single product, via a router push to also update the url.
        onProductSelected(sortedProducts.value[1]);
      } else if (sortedProducts.value.length > 2) { // All products + many products
        // Check if user has a preferred product set (as a user preference).
        const preferredProductPayload = {
          userId: auth0.user.value['https://accounts.ikea.com/networkid'],
          productCode: 'DFP',
          preferenceCode: 'PreferredProduct',
        };
        const { hasPreferenceValue } = await getHasPreferenceValue(preferredProductPayload);
        if (hasPreferenceValue) {
          const { preferenceValue } = await getPreferenceValue(preferredProductPayload);
          const preferredProduct = sortedProducts.value.find((p) => p.code === preferenceValue);
          if (preferredProduct) { // If PreferredProduct is in the list.
            onProductSelected(preferredProduct);
          } else {
            onProductSelected(sortedProducts.value[0]); // All products
          }
        } else {
          // select All products, via a router push
          onProductSelected(sortedProducts.value[0]);
        }
      } else { // nothing
        resetProduct();
      }
    });

    watch(
      () => route.query.productCode, (productCode) => {
        setProduct(productCode);
      },
    );

    return {
      mainTabs,
      dropref,
      sortedProducts,
      actorPicture,
      visible,
      actorProfileDropdown,
      hide,
      show,
      toHash,
      onProductSelected,
      onAtkomstClick,
      onDropdownItemSelected,
      isAuthenticated: auth0.isAuthenticated,
      loginWithRedirect: auth0.loginWithRedirect,
    };
  },
};
</script>

<style scoped>
.page-header {
  display: flex;
  align-items: center;
  height: 4rem;
  padding: 0.5rem;
  background-color: #151e44;
}

.page-header__title {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffcd3f;
}

.page-header__title:hover {
  cursor: pointer;
}

.page-header__heading {
  margin-right: 2.3125rem;
}

.page-header__user {
  margin-left: auto;
}

@media (min-width: 37.5rem) {
  .page-header {
    display: flex;
    height: 4.5rem;
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
}

@media (min-width: 64rem) {
  .page-header {
    flex-direction: row;
  }
}
</style>
